
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  


  DataItems: [
    { NO: '1',   CDATE: '2023-07-07', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '2',   CDATE: '2023-07-14', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '3',   CDATE: '2023-07-21', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '4',   CDATE: '2023-07-28', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '5',   CDATE: '2023-08-04', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '6',   CDATE: '2023-08-11', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '7',   CDATE: '2023-08-18', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '8',   CDATE: '2023-08-25', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '9',   CDATE: '2023-09-01', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '10',  CDATE: '2023-09-08', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '11',  CDATE: '2023-09-15', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '12',  CDATE: '2023-09-22', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '13',  CDATE: '2023-09-29', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '14',  CDATE: '2023-10-06', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '15',  CDATE: '2023-10-13', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '16',  CDATE: '2023-10-20', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '17',  CDATE: '2023-10-27', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '18',  CDATE: '2023-11-03', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '19',  CDATE: '2023-11-10', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '20',  CDATE: '2023-11-17', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '21',  CDATE: '2023-11-24', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '22',  CDATE: '2023-12-01', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '23',  CDATE: '2023-12-08', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '24',  CDATE: '2023-12-15', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '25',  CDATE: '2023-12-22', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '26',  CDATE: '2023-12-29', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '27',  CDATE: '2024-01-05', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '28',  CDATE: '2024-01-12', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '29',  CDATE: '2024-01-19', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '30',  CDATE: '2024-01-26', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '31',  CDATE: '2024-02-02', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '32',  CDATE: '2024-02-09', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '33',  CDATE: '2024-02-16', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '34',  CDATE: '2024-02-23', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '35',  CDATE: '2024-03-01', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '36',  CDATE: '2024-03-08', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '37',  CDATE: '2024-03-15', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '38',  CDATE: '2024-03-22', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '39',  CDATE: '2024-03-29', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '40',  CDATE: '2024-04-05', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '41',  CDATE: '2024-04-12', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '42',  CDATE: '2024-04-19', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '43',  CDATE: '2024-04-26', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '44',  CDATE: '2024-05-03', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '45',  CDATE: '2024-05-10', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '46',  CDATE: '2024-05-17', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '47',  CDATE: '2024-05-24', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '48',  CDATE: '2024-05-31', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '49',  CDATE: '2024-06-07', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '50',  CDATE: '2024-06-14', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '51',  CDATE: '2024-06-21', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '52',  CDATE: '2024-06-28', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '53',  CDATE: '2024-07-05', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '54',  CDATE: '2024-07-12', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '55',  CDATE: '2024-07-19', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '56',  CDATE: '2024-07-26', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '57',  CDATE: '2024-08-02', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '58',  CDATE: '2024-08-09', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '59',  CDATE: '2024-08-16', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '60',  CDATE: '2024-08-23', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '61',  CDATE: '2024-08-30', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '62',  CDATE: '2024-09-06', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '63',  CDATE: '2024-09-13', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '64',  CDATE: '2024-09-20', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '65',  CDATE: '2024-09-27', CO_EP: 0,    CO_LP: 0,    CO_A: 0,    CH_EP: 0,    CH_LP: 0,    CH_A: 0,    CT_EP: 0,    CT_LP: 0,    CT_A: 0,    CL_EP: 0,    CL_LP: 0,    CL_A: 0,    CR_EP: 0,    CR_LP: 0,    CR_A: 0,    CI_EP: 0,    CI_LP: 0,    CI_A: 0,    MO_EP: 0,    MO_LP: 0,    MO_A: 0,    MH_EP: 0,    MH_LP: 0,    MH_A: 0,    MT_EP: 0,    MT_LP: 0,    MT_A: 0,    ML_EP: 0,    ML_LP: 0,    ML_A: 0, },
{ NO: '66',  CDATE: '2024-10-04', CO_EP: 0,    CO_LP: 0,    CO_A: null, CH_EP: 0,    CH_LP: 0,    CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0,    CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '67',  CDATE: '2024-10-11', CO_EP: 0,    CO_LP: 0,    CO_A: null, CH_EP: 0,    CH_LP: 0,    CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0,    CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '68',  CDATE: '2024-10-18', CO_EP: 0,    CO_LP: 0,    CO_A: null, CH_EP: 0,    CH_LP: 0,    CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0,    CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '69',  CDATE: '2024-10-25', CO_EP: 0,    CO_LP: 0,    CO_A: null, CH_EP: 0,    CH_LP: 0,    CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0,    CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '70',  CDATE: '2024-11-01', CO_EP: 0,    CO_LP: 0,    CO_A: null, CH_EP: 0.1,  CH_LP: 0,    CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0,    CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '71',  CDATE: '2024-11-08', CO_EP: 0.1,  CO_LP: 0,    CO_A: null, CH_EP: 0.3,  CH_LP: 0,    CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0,    CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '72',  CDATE: '2024-11-15', CO_EP: 0.1,  CO_LP: 0.1,  CO_A: null, CH_EP: 0.5,  CH_LP: 0.2,  CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0.1,  CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '73',  CDATE: '2024-11-22', CO_EP: 0.2,  CO_LP: 0.1,  CO_A: null, CH_EP: 0.8,  CH_LP: 0.4,  CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0.2,  CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '74',  CDATE: '2024-11-29', CO_EP: 0.3,  CO_LP: 0.2,  CO_A: null, CH_EP: 1.3,  CH_LP: 0.6,  CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0.4,  CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '75',  CDATE: '2024-12-06', CO_EP: 0.5,  CO_LP: 0.2,  CO_A: null, CH_EP: 1.8,  CH_LP: 1,    CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 0.7,  CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '76',  CDATE: '2024-12-13', CO_EP: 0.6,  CO_LP: 0.4,  CO_A: null, CH_EP: 2.4,  CH_LP: 1.6,  CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 1.2,  CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '77',  CDATE: '2024-12-20', CO_EP: 0.8,  CO_LP: 0.5,  CO_A: null, CH_EP: 3,    CH_LP: 2.1,  CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 1.7,  CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '78',  CDATE: '2024-12-27', CO_EP: 1,    CO_LP: 0.6,  CO_A: null, CH_EP: 3.7,  CH_LP: 2.7,  CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 1.9,  CL_LP: 0,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '79',  CDATE: '2025-01-03', CO_EP: 1.2,  CO_LP: 0.8,  CO_A: null, CH_EP: 4.5,  CH_LP: 3.4,  CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 2.1,  CL_LP: 0.1,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '80',  CDATE: '2025-01-10', CO_EP: 1.4,  CO_LP: 1,    CO_A: null, CH_EP: 5.5,  CH_LP: 4.1,  CH_A: null, CT_EP: 0,    CT_LP: 0,    CT_A: null, CL_EP: 2.2,  CL_LP: 0.2,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '81',  CDATE: '2025-01-17', CO_EP: 1.9,  CO_LP: 1.3,  CO_A: null, CH_EP: 6.8,  CH_LP: 4.9,  CH_A: null, CT_EP: 0.2,  CT_LP: 0.2,  CT_A: null, CL_EP: 2.3,  CL_LP: 0.3,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '82',  CDATE: '2025-01-24', CO_EP: 2,    CO_LP: 1.5,  CO_A: 5,    CH_EP: 7.3,  CH_LP: 5.4,  CH_A: 5,    CT_EP: 0.3,  CT_LP: 0.2,  CT_A: 5,    CL_EP: 2.3,  CL_LP: 0.3,  CL_A: 5,    CR_EP: 0,    CR_LP: 0,    CR_A: 5,    CI_EP: 0,    CI_LP: 0,    CI_A: 5,    MO_EP: 0,    MO_LP: 0,    MO_A: 5,    MH_EP: 0,    MH_LP: 0,    MH_A: 5,    MT_EP: 0,    MT_LP: 0,    MT_A: 5,    ML_EP: 0,    ML_LP: 0,    ML_A: 5, },
{ NO: '83',  CDATE: '2025-01-31', CO_EP: 0.0,  CO_LP: 2,    CO_A: null, CH_EP: 8.4,  CH_LP: 6.8,  CH_A: null, CT_EP: 0.6,  CT_LP: 0.6,  CT_A: null, CL_EP: 2.3,  CL_LP: 0.3,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '84',  CDATE: '2025-02-07', CO_EP: 3.1,  CO_LP: 2.5,  CO_A: null, CH_EP: 9.8,  CH_LP: 7.9,  CH_A: null, CT_EP: 1,    CT_LP: 1,    CT_A: null, CL_EP: 2.5,  CL_LP: 0.4,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '85',  CDATE: '2025-02-14', CO_EP: 3.8,  CO_LP: 3.1,  CO_A: null, CH_EP: 11.5, CH_LP: 8.9,  CH_A: null, CT_EP: 1.4,  CT_LP: 1.3,  CT_A: null, CL_EP: 3,    CL_LP: 0.8,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '86',  CDATE: '2025-02-21', CO_EP: 4.7,  CO_LP: 3.6,  CO_A: null, CH_EP: 14.1, CH_LP: 10.2, CH_A: null, CT_EP: 1.7,  CT_LP: 1.6,  CT_A: null, CL_EP: 3.6,  CL_LP: 1.1,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '87',  CDATE: '2025-02-28', CO_EP: 5.7,  CO_LP: 4.4,  CO_A: 10,   CH_EP: 17,   CH_LP: 12.4, CH_A: 10,   CT_EP: 2.1,  CT_LP: 2,    CT_A: 10,   CL_EP: 4.3,  CL_LP: 1.4,  CL_A: 10,   CR_EP: 0,    CR_LP: 0,    CR_A: 10,   CI_EP: 0,    CI_LP: 0,    CI_A: 10,   MO_EP: 0,    MO_LP: 0,    MO_A: 10,   MH_EP: 0,    MH_LP: 0,    MH_A: 10,   MT_EP: 0,    MT_LP: 0,    MT_A: 10,   ML_EP: 0,    ML_LP: 0,    ML_A: 10, },
{ NO: '88',  CDATE: '2025-03-07', CO_EP: 6.9,  CO_LP: 5.4,  CO_A: null, CH_EP: 20.3, CH_LP: 15.2, CH_A: null, CT_EP: 2.8,  CT_LP: 2.5,  CT_A: null, CL_EP: 4.8,  CL_LP: 1.5,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '89',  CDATE: '2025-03-14', CO_EP: 8.2,  CO_LP: 6.6,  CO_A: null, CH_EP: 23.1, CH_LP: 18.3, CH_A: null, CT_EP: 3.6,  CT_LP: 3.3,  CT_A: null, CL_EP: 5.3,  CL_LP: 1.6,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '90',  CDATE: '2025-03-21', CO_EP: 9.5,  CO_LP: 8,    CO_A: null, CH_EP: 25.7, CH_LP: 21.3, CH_A: null, CT_EP: 4.6,  CT_LP: 4.2,  CT_A: null, CL_EP: 5.7,  CL_LP: 1.7,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '91',  CDATE: '2025-03-28', CO_EP: 10.5, CO_LP: 9.1,  CO_A: 15,   CH_EP: 27.5, CH_LP: 23.7, CH_A: 15,   CT_EP: 5.5,  CT_LP: 5.1,  CT_A: 15,   CL_EP: 6.2,  CL_LP: 1.8,  CL_A: 15,   CR_EP: 0,    CR_LP: 0,    CR_A: 15,   CI_EP: 0,    CI_LP: 0,    CI_A: 15,   MO_EP: 0,    MO_LP: 0,    MO_A: 15,   MH_EP: 0,    MH_LP: 0,    MH_A: 15,   MT_EP: 0,    MT_LP: 0,    MT_A: 15,   ML_EP: 0,    ML_LP: 0,    ML_A: 15, },
{ NO: '92',  CDATE: '2025-04-04', CO_EP: 11.8, CO_LP: 10.2, CO_A: null, CH_EP: 29.9, CH_LP: 25.7, CH_A: null, CT_EP: 6.4,  CT_LP: 5.9,  CT_A: null, CL_EP: 6.6,  CL_LP: 2,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '93',  CDATE: '2025-04-11', CO_EP: 13.3, CO_LP: 11.4, CO_A: null, CH_EP: 34,   CH_LP: 28.3, CH_A: null, CT_EP: 7.2,  CT_LP: 6.8,  CT_A: null, CL_EP: 6.8,  CL_LP: 2.3,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '94',  CDATE: '2025-04-18', CO_EP: 15.1, CO_LP: 13.1, CO_A: null, CH_EP: 38.4, CH_LP: 32.5, CH_A: null, CT_EP: 8.3,  CT_LP: 7.8,  CT_A: null, CL_EP: 7.4,  CL_LP: 2.5,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '95',  CDATE: '2025-04-25', CO_EP: 16.6, CO_LP: 14.7, CO_A: 20,   CH_EP: 41.3, CH_LP: 35.6, CH_A: 20,   CT_EP: 9.5,  CT_LP: 9,    CT_A: 20,   CL_EP: 7.5,  CL_LP: 2.8,  CL_A: 20,   CR_EP: 0,    CR_LP: 0,    CR_A: 20,   CI_EP: 0,    CI_LP: 0,    CI_A: 20,   MO_EP: 0,    MO_LP: 0,    MO_A: 20,   MH_EP: 0,    MH_LP: 0,    MH_A: 20,   MT_EP: 0,    MT_LP: 0,    MT_A: 20,   ML_EP: 0,    ML_LP: 0,    ML_A: 20, },
{ NO: '96',  CDATE: '2025-05-02', CO_EP: 18.1, CO_LP: 16.2, CO_A: null, CH_EP: 43.8, CH_LP: 38.1, CH_A: null, CT_EP: 10.9, CT_LP: 10.3, CT_A: null, CL_EP: 7.7,  CL_LP: 3,    CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '97',  CDATE: '2025-05-09', CO_EP: 19.9, CO_LP: 17.9, CO_A: null, CH_EP: 46.3, CH_LP: 40.8, CH_A: null, CT_EP: 12.6, CT_LP: 11.9, CT_A: null, CL_EP: 7.9,  CL_LP: 3.3,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '98',  CDATE: '2025-05-16', CO_EP: 21.6, CO_LP: 19.7, CO_A: null, CH_EP: 48.3, CH_LP: 43.4, CH_A: null, CT_EP: 14.4, CT_LP: 13.6, CT_A: null, CL_EP: 8.1,  CL_LP: 3.8,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '99',  CDATE: '2025-05-23', CO_EP: 23.6, CO_LP: 21.5, CO_A: null, CH_EP: 51.3, CH_LP: 45.8, CH_A: null, CT_EP: 16.3, CT_LP: 15.4, CT_A: null, CL_EP: 8.4,  CL_LP: 4.2,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '100', CDATE: '2025-05-30', CO_EP: 25.1, CO_LP: 22.9, CO_A: 25,   CH_EP: 54.6, CH_LP: 47.9, CH_A: 25,   CT_EP: 17.4, CT_LP: 16.7, CT_A: 25,   CL_EP: 8.8,  CL_LP: 4.6,  CL_A: 25,   CR_EP: 0,    CR_LP: 0,    CR_A: 25,   CI_EP: 0,    CI_LP: 0,    CI_A: 25,   MO_EP: 0,    MO_LP: 0,    MO_A: 25,   MH_EP: 0,    MH_LP: 0,    MH_A: 25,   MT_EP: 0,    MT_LP: 0,    MT_A: 25,   ML_EP: 0,    ML_LP: 0,    ML_A: 25, },
{ NO: '101', CDATE: '2025-06-06', CO_EP: 26.8, CO_LP: 24.4, CO_A: null, CH_EP: 59.3, CH_LP: 51.2, CH_A: null, CT_EP: 18.2, CT_LP: 17.8, CT_A: null, CL_EP: 9.3,  CL_LP: 4.8,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '102', CDATE: '2025-06-13', CO_EP: 28.4, CO_LP: 26.1, CO_A: null, CH_EP: 63,   CH_LP: 56.3, CH_A: null, CT_EP: 19.2, CT_LP: 18.6, CT_A: null, CL_EP: 9.9,  CL_LP: 4.9,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '103', CDATE: '2025-06-20', CO_EP: 30.1, CO_LP: 27.8, CO_A: null, CH_EP: 65.5, CH_LP: 60.7, CH_A: null, CT_EP: 20.8, CT_LP: 19.6, CT_A: null, CL_EP: 10.4, CL_LP: 5.1,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '104', CDATE: '2025-06-27', CO_EP: 32.1, CO_LP: 29.8, CO_A: 30,   CH_EP: 67.5, CH_LP: 63.5, CH_A: 30,   CT_EP: 23,   CT_LP: 21.5, CT_A: 30,   CL_EP: 11,   CL_LP: 5.5,  CL_A: 30,   CR_EP: 0,    CR_LP: 0,    CR_A: 30,   CI_EP: 0,    CI_LP: 0,    CI_A: 30,   MO_EP: 0,    MO_LP: 0,    MO_A: 30,   MH_EP: 0,    MH_LP: 0,    MH_A: 30,   MT_EP: 0,    MT_LP: 0,    MT_A: 30,   ML_EP: 0,    ML_LP: 0,    ML_A: 30, },
{ NO: '105', CDATE: '2025-07-04', CO_EP: 34,   CO_LP: 32.1, CO_A: null, CH_EP: 69.1, CH_LP: 65.9, CH_A: null, CT_EP: 25.2, CT_LP: 24,   CT_A: null, CL_EP: 11.7, CL_LP: 5.9,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '106', CDATE: '2025-07-11', CO_EP: 35.6, CO_LP: 34,   CO_A: null, CH_EP: 70.2, CH_LP: 67.9, CH_A: null, CT_EP: 27.2, CT_LP: 26.1, CT_A: null, CL_EP: 12.4, CL_LP: 6.2,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '107', CDATE: '2025-07-18', CO_EP: 36.9, CO_LP: 35.5, CO_A: null, CH_EP: 71.7, CH_LP: 69.1, CH_A: null, CT_EP: 28.6, CT_LP: 27.8, CT_A: null, CL_EP: 13.4, CL_LP: 6.6,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '108', CDATE: '2025-07-25', CO_EP: 37,   CO_LP: 35.5, CO_A: 35,   CH_EP: 71.7, CH_LP: 69.2, CH_A: 35,   CT_EP: 28.6, CT_LP: 27.8, CT_A: 35,   CL_EP: 14.8, CL_LP: 6.8,  CL_A: 35,   CR_EP: 0,    CR_LP: 0,    CR_A: 35,   CI_EP: 0,    CI_LP: 0,    CI_A: 35,   MO_EP: 0,    MO_LP: 0,    MO_A: 35,   MH_EP: 0,    MH_LP: 0,    MH_A: 35,   MT_EP: 0,    MT_LP: 0,    MT_A: 35,   ML_EP: 0,    ML_LP: 0,    ML_A: 35, },
{ NO: '109', CDATE: '2025-08-01', CO_EP: 37.4, CO_LP: 35.8, CO_A: null, CH_EP: 72.3, CH_LP: 69.4, CH_A: null, CT_EP: 28.8, CT_LP: 28.1, CT_A: null, CL_EP: 16.3, CL_LP: 7.1,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '110', CDATE: '2025-08-08', CO_EP: 38.4, CO_LP: 36.7, CO_A: null, CH_EP: 74.4, CH_LP: 70.3, CH_A: null, CT_EP: 29.5, CT_LP: 29.1, CT_A: null, CL_EP: 18.5, CL_LP: 7.3,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '111', CDATE: '2025-08-15', CO_EP: 39.8, CO_LP: 37.5, CO_A: null, CH_EP: 77.4, CH_LP: 71.7, CH_A: null, CT_EP: 30.3, CT_LP: 29.9, CT_A: null, CL_EP: 20.9, CL_LP: 7.5,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '112', CDATE: '2025-08-22', CO_EP: 41.6, CO_LP: 38.8, CO_A: null, CH_EP: 80.2, CH_LP: 73.9, CH_A: null, CT_EP: 31.8, CT_LP: 31,   CT_A: null, CL_EP: 23.8, CL_LP: 7.9,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '113', CDATE: '2025-08-29', CO_EP: 43.8, CO_LP: 40.6, CO_A: 40,   CH_EP: 82.1, CH_LP: 76.6, CH_A: 40,   CT_EP: 34.1, CT_LP: 32.7, CT_A: 40,   CL_EP: 26.5, CL_LP: 8.5,  CL_A: 40,   CR_EP: 0,    CR_LP: 0,    CR_A: 40,   CI_EP: 0,    CI_LP: 0,    CI_A: 40,   MO_EP: 0,    MO_LP: 0,    MO_A: 40,   MH_EP: 0,    MH_LP: 0,    MH_A: 40,   MT_EP: 0,    MT_LP: 0,    MT_A: 40,   ML_EP: 0,    ML_LP: 0,    ML_A: 40, },
{ NO: '114', CDATE: '2025-09-05', CO_EP: 46.2, CO_LP: 43.3, CO_A: null, CH_EP: 83.5, CH_LP: 79.4, CH_A: null, CT_EP: 37.1, CT_LP: 35.5, CT_A: null, CL_EP: 28.8, CL_LP: 9.4,  CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0,    MO_LP: 0,    MO_A: null, MH_EP: 0.2,  MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '115', CDATE: '2025-09-12', CO_EP: 49,   CO_LP: 46.6, CO_A: null, CH_EP: 84.4, CH_LP: 81.7, CH_A: null, CT_EP: 40.8, CT_LP: 39.6, CT_A: null, CL_EP: 31,   CL_LP: 10.9, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0.2,  MO_LP: 0,    MO_A: null, MH_EP: 0.6,  MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '116', CDATE: '2025-09-19', CO_EP: 50.7, CO_LP: 48.5, CO_A: null, CH_EP: 85.2, CH_LP: 82.8, CH_A: null, CT_EP: 42.9, CT_LP: 41.9, CT_A: null, CL_EP: 33.1, CL_LP: 12.8, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 0.5,  MO_LP: 0,    MO_A: null, MH_EP: 2,    MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '117', CDATE: '2025-09-26', CO_EP: 51.5, CO_LP: 49.3, CO_A: 45,   CH_EP: 85.7, CH_LP: 83.2, CH_A: 45,   CT_EP: 43.8, CT_LP: 42.8, CT_A: 45,   CL_EP: 35.9, CL_LP: 15.1, CL_A: 45,   CR_EP: 0,    CR_LP: 0,    CR_A: 45,   CI_EP: 0,    CI_LP: 0,    CI_A: 45,   MO_EP: 1,    MO_LP: 0,    MO_A: 45,   MH_EP: 4,    MH_LP: 0,    MH_A: 45,   MT_EP: 0,    MT_LP: 0,    MT_A: 45,   ML_EP: 0,    ML_LP: 0,    ML_A: 45, },
{ NO: '118', CDATE: '2025-10-03', CO_EP: 52,   CO_LP: 49.6, CO_A: null, CH_EP: 86,   CH_LP: 83.4, CH_A: null, CT_EP: 44.2, CT_LP: 43,   CT_A: null, CL_EP: 39.3, CL_LP: 17,   CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 1.4,  MO_LP: 0,    MO_A: null, MH_EP: 5.5,  MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '119', CDATE: '2025-10-10', CO_EP: 53.6, CO_LP: 50.2, CO_A: null, CH_EP: 86.8, CH_LP: 83.9, CH_A: null, CT_EP: 46,   CT_LP: 43.6, CT_A: null, CL_EP: 42.4, CL_LP: 18.7, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 2.6,  MO_LP: 0,    MO_A: null, MH_EP: 10.3, MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '120', CDATE: '2025-10-17', CO_EP: 56,   CO_LP: 51.2, CO_A: null, CH_EP: 87.8, CH_LP: 84.5, CH_A: null, CT_EP: 48.9, CT_LP: 44.8, CT_A: null, CL_EP: 45.8, CL_LP: 20.3, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 4.2,  MO_LP: 0,    MO_A: null, MH_EP: 16.8, MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '121', CDATE: '2025-10-24', CO_EP: 58.6, CO_LP: 53.3, CO_A: null, CH_EP: 89.2, CH_LP: 85.4, CH_A: null, CT_EP: 52.1, CT_LP: 47.5, CT_A: null, CL_EP: 50.2, CL_LP: 21.3, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 6,    MO_LP: 0,    MO_A: null, MH_EP: 23.9, MH_LP: 0,    MH_A: null, MT_EP: 0,    MT_LP: 0,    MT_A: null, ML_EP: 0,    ML_LP: 0,    ML_A: null, },
{ NO: '122', CDATE: '2025-10-31', CO_EP: 61.1, CO_LP: 55.4, CO_A: 50,   CH_EP: 90.7, CH_LP: 86.5, CH_A: 50,   CT_EP: 55,   CT_LP: 50.3, CT_A: 50,   CL_EP: 54.3, CL_LP: 22.2, CL_A: 50,   CR_EP: 0,    CR_LP: 0,    CR_A: 50,   CI_EP: 0,    CI_LP: 0,    CI_A: 50,   MO_EP: 7.7,  MO_LP: 0,    MO_A: 50,   MH_EP: 30.6, MH_LP: 0.1,  MH_A: 50,   MT_EP: 0.1,  MT_LP: 0,    MT_A: 50,   ML_EP: 0,    ML_LP: 0,    ML_A: 50, },
{ NO: '123', CDATE: '2025-11-07', CO_EP: 63.4, CO_LP: 57.2, CO_A: null, CH_EP: 92.3, CH_LP: 87.7, CH_A: null, CT_EP: 57.6, CT_LP: 52.4, CT_A: null, CL_EP: 57.7, CL_LP: 23,   CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 9.2,  MO_LP: 0.1,  MO_A: null, MH_EP: 36.4, MH_LP: 0.2,  MH_A: null, MT_EP: 0.2,  MT_LP: 0.1,  MT_A: null, ML_EP: 0.1,  ML_LP: 0,    ML_A: null, },
{ NO: '124', CDATE: '2025-11-14', CO_EP: 65.4, CO_LP: 58.7, CO_A: null, CH_EP: 93.6, CH_LP: 89,   CH_A: null, CT_EP: 59.9, CT_LP: 54.1, CT_A: null, CL_EP: 60.6, CL_LP: 24.1, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 10.9, MO_LP: 0.4,  MO_A: null, MH_EP: 41.8, MH_LP: 0.7,  MH_A: null, MT_EP: 0.6,  MT_LP: 0.3,  MT_A: null, ML_EP: 0.4,  ML_LP: 0,    ML_A: null, },
{ NO: '125', CDATE: '2025-11-21', CO_EP: 67.2, CO_LP: 60,   CO_A: null, CH_EP: 94.8, CH_LP: 90,   CH_A: null, CT_EP: 62,   CT_LP: 55.7, CT_A: null, CL_EP: 63.9, CL_LP: 25.5, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 12.9, MO_LP: 0.9,  MO_A: null, MH_EP: 47.7, MH_LP: 1.8,  MH_A: null, MT_EP: 1.3,  MT_LP: 0.7,  MT_A: null, ML_EP: 1,    ML_LP: 0,    ML_A: null, },
{ NO: '126', CDATE: '2025-11-28', CO_EP: 68.8, CO_LP: 61.2, CO_A: 55,   CH_EP: 95.8, CH_LP: 90.9, CH_A: 55,   CT_EP: 63.7, CT_LP: 57,   CT_A: 55,   CL_EP: 68.1, CL_LP: 27.4, CL_A: 55,   CR_EP: 0,    CR_LP: 0,    CR_A: 55,   CI_EP: 0,    CI_LP: 0,    CI_A: 55,   MO_EP: 15.7, MO_LP: 2,    MO_A: 55,   MH_EP: 55,   MH_LP: 4.1,  MH_A: 55,   MT_EP: 2.5,  MT_LP: 1.4,  MT_A: 55,   ML_EP: 2.3,  ML_LP: 0,    ML_A: 55, },
{ NO: '127', CDATE: '2025-12-05', CO_EP: 70.4, CO_LP: 62.5, CO_A: null, CH_EP: 96.7, CH_LP: 91.8, CH_A: null, CT_EP: 65.4, CT_LP: 58.4, CT_A: null, CL_EP: 72.9, CL_LP: 29.9, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 19.1, MO_LP: 3.8,  MO_A: null, MH_EP: 62.9, MH_LP: 8,    MH_A: null, MT_EP: 4.6,  MT_LP: 2.6,  MT_A: null, ML_EP: 4.5,  ML_LP: 0.1,  ML_A: null, },
{ NO: '128', CDATE: '2025-12-12', CO_EP: 72.1, CO_LP: 63.9, CO_A: null, CH_EP: 97.6, CH_LP: 92.8, CH_A: null, CT_EP: 67.2, CT_LP: 60,   CT_A: null, CL_EP: 78.1, CL_LP: 33,   CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 23.1, MO_LP: 6.4,  MO_A: null, MH_EP: 70.2, MH_LP: 13.2, MH_A: null, MT_EP: 7.3,  MT_LP: 4.4,  MT_A: null, ML_EP: 8,    ML_LP: 0.2,  ML_A: null, },
{ NO: '129', CDATE: '2025-12-19', CO_EP: 73.2, CO_LP: 65.4, CO_A: null, CH_EP: 98.2, CH_LP: 93.7, CH_A: null, CT_EP: 68.4, CT_LP: 61.5, CT_A: null, CL_EP: 82.5, CL_LP: 36.9, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 26.5, MO_LP: 8.7,  MO_A: null, MH_EP: 75.5, MH_LP: 17.7, MH_A: null, MT_EP: 10,   MT_LP: 6.1,  MT_A: null, ML_EP: 13.1, ML_LP: 1,    ML_A: null, },
{ NO: '130', CDATE: '2025-12-26', CO_EP: 74.1, CO_LP: 66.8, CO_A: 60,   CH_EP: 98.5, CH_LP: 94.5, CH_A: 60,   CT_EP: 69.3, CT_LP: 62.9, CT_A: 60,   CL_EP: 86,   CL_LP: 41.5, CL_A: 60,   CR_EP: 0,    CR_LP: 0,    CR_A: 60,   CI_EP: 0,    CI_LP: 0,    CI_A: 60,   MO_EP: 30.2, MO_LP: 11.7, MO_A: 60,   MH_EP: 80.3, MH_LP: 23.2, MH_A: 60,   MT_EP: 13.1, MT_LP: 8.3,  MT_A: 60,   ML_EP: 18.5, ML_LP: 2.3,  ML_A: 60, },
{ NO: '131', CDATE: '2026-01-02', CO_EP: 75,   CO_LP: 68.2, CO_A: null, CH_EP: 98.9, CH_LP: 95.5, CH_A: null, CT_EP: 70.3, CT_LP: 64.5, CT_A: null, CL_EP: 89.8, CL_LP: 46.2, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 34.9, MO_LP: 16.1, MO_A: null, MH_EP: 86.3, MH_LP: 31.4, MH_A: null, MT_EP: 17.2, MT_LP: 11.5, MT_A: null, ML_EP: 24.7, ML_LP: 4.4,  ML_A: null, },
{ NO: '132', CDATE: '2026-01-09', CO_EP: 75.8, CO_LP: 69.6, CO_A: null, CH_EP: 99.2, CH_LP: 96.3, CH_A: null, CT_EP: 71,   CT_LP: 65.9, CT_A: null, CL_EP: 93.1, CL_LP: 50.6, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 39.6, MO_LP: 21.3, MO_A: null, MH_EP: 92,   MH_LP: 41.8, MH_A: null, MT_EP: 21.4, MT_LP: 14.9, MT_A: null, ML_EP: 31.4, ML_LP: 8,    ML_A: null, },
{ NO: '133', CDATE: '2026-01-16', CO_EP: 76.4, CO_LP: 70.9, CO_A: null, CH_EP: 99.5, CH_LP: 97.2, CH_A: null, CT_EP: 71.7, CT_LP: 67.2, CT_A: null, CL_EP: 94.7, CL_LP: 54.6, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 44.1, MO_LP: 26.9, MO_A: null, MH_EP: 96.7, MH_LP: 53.3, MH_A: null, MT_EP: 25.5, MT_LP: 18.5, MT_A: null, ML_EP: 40.1, ML_LP: 12.9, ML_A: null, },
{ NO: '134', CDATE: '2026-01-23', CO_EP: 76.9, CO_LP: 72.2, CO_A: null, CH_EP: 99.6, CH_LP: 97.9, CH_A: null, CT_EP: 72.3, CT_LP: 68.6, CT_A: null, CL_EP: 96,   CL_LP: 58.5, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 48,   MO_LP: 32.7, MO_A: null, MH_EP: 99.2, MH_LP: 64.9, MH_A: null, MT_EP: 29.5, MT_LP: 22.2, MT_A: null, ML_EP: 51.8, ML_LP: 18.4, ML_A: null, },
{ NO: '135', CDATE: '2026-01-30', CO_EP: 77.3, CO_LP: 73.5, CO_A: 65,   CH_EP: 99.7, CH_LP: 98.5, CH_A: 65,   CT_EP: 72.8, CT_LP: 70,   CT_A: 65,   CL_EP: 97.3, CL_LP: 62.6, CL_A: 65,   CR_EP: 0,    CR_LP: 0,    CR_A: 65,   CI_EP: 0,    CI_LP: 0,    CI_A: 65,   MO_EP: 51.3, MO_LP: 38.5, MO_A: 65,   MH_EP: 99.9, MH_LP: 76.7, MH_A: 65,   MT_EP: 33,   MT_LP: 25.9, MT_A: 65,   ML_EP: 64.6, ML_LP: 24.3, ML_A: 65, },
{ NO: '136', CDATE: '2026-02-06', CO_EP: 77.6, CO_LP: 74.5, CO_A: null, CH_EP: 99.8, CH_LP: 99.1, CH_A: null, CT_EP: 73.2, CT_LP: 71.3, CT_A: null, CL_EP: 97.6, CL_LP: 63.9, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 53.4, MO_LP: 44,   MO_A: null, MH_EP: 100,  MH_LP: 87.9, MH_A: null, MT_EP: 35.7, MT_LP: 29.6, MT_A: null, ML_EP: 67.9, ML_LP: 26,   ML_A: null, },
{ NO: '137', CDATE: '2026-02-13', CO_EP: 77.7, CO_LP: 74.8, CO_A: null, CH_EP: 99.9, CH_LP: 99.4, CH_A: null, CT_EP: 73.4, CT_LP: 71.7, CT_A: null, CL_EP: 97.6, CL_LP: 63.9, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 54,   MO_LP: 45.9, MO_A: null, MH_EP: 100,  MH_LP: 91.6, MH_A: null, MT_EP: 36.5, MT_LP: 31,   MT_A: null, ML_EP: 67.9, ML_LP: 26,   ML_A: null, },
{ NO: '138', CDATE: '2026-02-20', CO_EP: 77.9, CO_LP: 75.5, CO_A: null, CH_EP: 100,  CH_LP: 99.8, CH_A: null, CT_EP: 73.6, CT_LP: 72.6, CT_A: null, CL_EP: 97.6, CL_LP: 63.9, CL_A: null, CR_EP: 0,    CR_LP: 0,    CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 54.9, MO_LP: 49.5, MO_A: null, MH_EP: 100,  MH_LP: 97.4, MH_A: null, MT_EP: 37.9, MT_LP: 34,   MT_A: null, ML_EP: 67.9, ML_LP: 26,   ML_A: null, },
{ NO: '139', CDATE: '2026-02-27', CO_EP: 78,   CO_LP: 76.1, CO_A: 70,   CH_EP: 100,  CH_LP: 100,  CH_A: 70,   CT_EP: 73.8, CT_LP: 73.2, CT_A: 70,   CL_EP: 98.3, CL_LP: 66.8, CL_A: 70,   CR_EP: 0,    CR_LP: 0,    CR_A: 70,   CI_EP: 0,    CI_LP: 0,    CI_A: 70,   MO_EP: 55.9, MO_LP: 51.8, MO_A: 70,   MH_EP: 100,  MH_LP: 99.6, MH_A: 70,   MT_EP: 38.7, MT_LP: 36.3, MT_A: 70,   ML_EP: 77,   ML_LP: 30.4, ML_A: 70, },
{ NO: '140', CDATE: '2026-03-06', CO_EP: 78.2, CO_LP: 76.6, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 73.9, CT_LP: 73.6, CT_A: null, CL_EP: 98.8, CL_LP: 71.2, CL_A: null, CR_EP: 33.3, CR_LP: 33.3, CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 56.7, MO_LP: 53.3, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.1, MT_LP: 37.7, MT_A: null, ML_EP: 86.7, ML_LP: 37.8, ML_A: null, },
{ NO: '141', CDATE: '2026-03-13', CO_EP: 78.2, CO_LP: 77.1, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 73.9, CT_LP: 73.8, CT_A: null, CL_EP: 99.2, CL_LP: 76.5, CL_A: null, CR_EP: 33.3, CR_LP: 33.3, CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 57.2, MO_LP: 54.3, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.3, MT_LP: 38.6, MT_A: null, ML_EP: 93.5, ML_LP: 46.8, ML_A: null, },
{ NO: '142', CDATE: '2026-03-20', CO_EP: 78.4, CO_LP: 77.5, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 74,   CT_LP: 74,   CT_A: null, CL_EP: 99.4, CL_LP: 82,   CL_A: null, CR_EP: 61,   CR_LP: 61,   CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 57.4, MO_LP: 55.2, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.3, MT_LP: 39.1, MT_A: null, ML_EP: 97.3, ML_LP: 56.4, ML_A: null, },
{ NO: '143', CDATE: '2026-03-27', CO_EP: 78.5, CO_LP: 77.9, CO_A: 75,   CH_EP: 100,  CH_LP: 100,  CH_A: 75,   CT_EP: 74.1, CT_LP: 74.1, CT_A: 75,   CL_EP: 99.5, CL_LP: 87.5, CL_A: 75,   CR_EP: 66.7, CR_LP: 66.7, CR_A: 75,   CI_EP: 0,    CI_LP: 0,    CI_A: 75,   MO_EP: 57.5, MO_LP: 55.8, MO_A: 75,   MH_EP: 100,  MH_LP: 100,  MH_A: 75,   MT_EP: 39.3, MT_LP: 39.3, MT_A: 75,   ML_EP: 99,   ML_LP: 67,   ML_A: 75, },
{ NO: '144', CDATE: '2026-04-03', CO_EP: 78.6, CO_LP: 78.2, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 74.3, CT_LP: 74.2, CT_A: null, CL_EP: 99.5, CL_LP: 92.3, CL_A: null, CR_EP: 94.4, CR_LP: 66.7, CR_A: null, CI_EP: 0,    CI_LP: 0,    CI_A: null, MO_EP: 57.5, MO_LP: 56.4, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.3, MT_LP: 39.3, MT_A: null, ML_EP: 99.8, ML_LP: 78.3, ML_A: null, },
{ NO: '145', CDATE: '2026-04-10', CO_EP: 78.7, CO_LP: 78.5, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 74.5, CT_LP: 74.3, CT_A: null, CL_EP: 99.6, CL_LP: 96,   CL_A: null, CR_EP: 100,  CR_LP: 99.9, CR_A: null, CI_EP: 0.1,  CI_LP: 0.1,  CI_A: null, MO_EP: 57.5, MO_LP: 56.9, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.3, MT_LP: 39.3, MT_A: null, ML_EP: 100,  ML_LP: 88.2, ML_A: null, },
{ NO: '146', CDATE: '2026-04-17', CO_EP: 78.9, CO_LP: 78.6, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 74.7, CT_LP: 74.3, CT_A: null, CL_EP: 99.6, CL_LP: 97.9, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 0.5,  CI_LP: 0.1,  CI_A: null, MO_EP: 57.5, MO_LP: 57.3, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.3, MT_LP: 39.3, MT_A: null, ML_EP: 100,  ML_LP: 94.7, ML_A: null, },
{ NO: '147', CDATE: '2026-04-24', CO_EP: 79.1, CO_LP: 78.7, CO_A: 80,   CH_EP: 100,  CH_LP: 100,  CH_A: 80,   CT_EP: 74.9, CT_LP: 74.4, CT_A: 80,   CL_EP: 99.6, CL_LP: 99,   CL_A: 80,   CR_EP: 100,  CR_LP: 100,  CR_A: 80,   CI_EP: 1.1,  CI_LP: 0.3,  CI_A: 80,   MO_EP: 57.5, MO_LP: 57.4, MO_A: 80,   MH_EP: 100,  MH_LP: 100,  MH_A: 80,   MT_EP: 39.3, MT_LP: 39.3, MT_A: 80,   ML_EP: 100,  ML_LP: 98.2, ML_A: 80, },
{ NO: '148', CDATE: '2026-05-01', CO_EP: 79.3, CO_LP: 78.8, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 75.2, CT_LP: 74.5, CT_A: null, CL_EP: 99.6, CL_LP: 99.5, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 1.5,  CI_LP: 0.5,  CI_A: null, MO_EP: 57.5, MO_LP: 57.5, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.3, MT_LP: 39.3, MT_A: null, ML_EP: 100,  ML_LP: 99.5, ML_A: null, },
{ NO: '149', CDATE: '2026-05-08', CO_EP: 79.6, CO_LP: 78.9, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 75.6, CT_LP: 74.6, CT_A: null, CL_EP: 99.6, CL_LP: 99.6, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 2.2,  CI_LP: 1,    CI_A: null, MO_EP: 57.5, MO_LP: 57.5, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.3, MT_LP: 39.3, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '150', CDATE: '2026-05-15', CO_EP: 80.1, CO_LP: 79,   CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 76.3, CT_LP: 74.7, CT_A: null, CL_EP: 99.6, CL_LP: 99.6, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 4.1,  CI_LP: 1.9,  CI_A: null, MO_EP: 57.5, MO_LP: 57.5, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.3, MT_LP: 39.3, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '151', CDATE: '2026-05-22', CO_EP: 80.9, CO_LP: 79.2, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 77.2, CT_LP: 74.9, CT_A: null, CL_EP: 99.7, CL_LP: 99.6, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 7.8,  CI_LP: 3.4,  CI_A: null, MO_EP: 57.6, MO_LP: 57.5, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.4, MT_LP: 39.3, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '152', CDATE: '2026-05-29', CO_EP: 81.9, CO_LP: 79.5, CO_A: 85,   CH_EP: 100,  CH_LP: 100,  CH_A: 85,   CT_EP: 78.4, CT_LP: 75.2, CT_A: 85,   CL_EP: 99.8, CL_LP: 99.7, CL_A: 85,   CR_EP: 100,  CR_LP: 100,  CR_A: 85,   CI_EP: 11.5, CI_LP: 5.6,  CI_A: 85,   MO_EP: 57.6, MO_LP: 57.5, MO_A: 85,   MH_EP: 100,  MH_LP: 100,  MH_A: 85,   MT_EP: 39.4, MT_LP: 39.3, MT_A: 85,   ML_EP: 100,  ML_LP: 100,  ML_A: 85, },
{ NO: '153', CDATE: '2026-06-05', CO_EP: 83,   CO_LP: 79.9, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 79.9, CT_LP: 75.6, CT_A: null, CL_EP: 99.8, CL_LP: 99.8, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 15.7, CI_LP: 8.8,  CI_A: null, MO_EP: 57.7, MO_LP: 57.5, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.6, MT_LP: 39.4, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '154', CDATE: '2026-06-12', CO_EP: 84.5, CO_LP: 80.4, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 81.7, CT_LP: 76.1, CT_A: null, CL_EP: 99.9, CL_LP: 99.8, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 20.9, CI_LP: 13.4, CI_A: null, MO_EP: 57.9, MO_LP: 57.6, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 39.8, MT_LP: 39.4, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '155', CDATE: '2026-06-19', CO_EP: 86,   CO_LP: 81.1, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 83.6, CT_LP: 76.8, CT_A: null, CL_EP: 100,  CL_LP: 99.8, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 27.2, CI_LP: 18.6, CI_A: null, MO_EP: 58.1, MO_LP: 57.6, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 40.2, MT_LP: 39.5, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '156', CDATE: '2026-06-26', CO_EP: 87.7, CO_LP: 81.9, CO_A: 90,   CH_EP: 100,  CH_LP: 100,  CH_A: 90,   CT_EP: 85.6, CT_LP: 77.5, CT_A: 90,   CL_EP: 100,  CL_LP: 99.8, CL_A: 90,   CR_EP: 100,  CR_LP: 100,  CR_A: 90,   CI_EP: 34.5, CI_LP: 26.4, CI_A: 90,   MO_EP: 58.6, MO_LP: 57.8, MO_A: 90,   MH_EP: 100,  MH_LP: 100,  MH_A: 90,   MT_EP: 40.9, MT_LP: 39.7, MT_A: 90,   ML_EP: 100,  ML_LP: 100,  ML_A: 90, },
{ NO: '157', CDATE: '2026-07-03', CO_EP: 89.3, CO_LP: 82.9, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 87.6, CT_LP: 78.5, CT_A: null, CL_EP: 100,  CL_LP: 99.8, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 43.7, CI_LP: 35,   CI_A: null, MO_EP: 59.3, MO_LP: 58,   MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 41.8, MT_LP: 40,   MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '158', CDATE: '2026-07-10', CO_EP: 90.9, CO_LP: 83.9, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 89.3, CT_LP: 79.5, CT_A: null, CL_EP: 100,  CL_LP: 99.9, CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 53.4, CI_LP: 44.6, CI_A: null, MO_EP: 60.3, MO_LP: 58.3, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 43.3, MT_LP: 40.5, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '159', CDATE: '2026-07-17', CO_EP: 92.4, CO_LP: 85,   CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 90.9, CT_LP: 80.5, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 62.7, CI_LP: 53.5, CI_A: null, MO_EP: 61.7, MO_LP: 58.9, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 45.2, MT_LP: 41.2, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '160', CDATE: '2026-07-24', CO_EP: 92.6, CO_LP: 85,   CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 90.9, CT_LP: 80.5, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 67,   CI_LP: 53.5, CI_A: null, MO_EP: 61.7, MO_LP: 58.9, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 45.2, MT_LP: 41.2, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '161', CDATE: '2026-07-31', CO_EP: 92.8, CO_LP: 85.2, CO_A: 95,   CH_EP: 100,  CH_LP: 100,  CH_A: 95,   CT_EP: 91.2, CT_LP: 80.7, CT_A: 95,   CL_EP: 100,  CL_LP: 100,  CL_A: 95,   CR_EP: 100,  CR_LP: 100,  CR_A: 95,   CI_EP: 68,   CI_LP: 55.6, CI_A: 95,   MO_EP: 62,   MO_LP: 59,   MO_A: 95,   MH_EP: 100,  MH_LP: 100,  MH_A: 95,   MT_EP: 45.7, MT_LP: 41.4, MT_A: 95,   ML_EP: 100,  ML_LP: 100,  ML_A: 95, },
{ NO: '162', CDATE: '2026-08-07', CO_EP: 93.9, CO_LP: 86.4, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 92.5, CT_LP: 81.8, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 73.3, CI_LP: 66.1, CI_A: null, MO_EP: 63.9, MO_LP: 59.8, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 48.4, MT_LP: 42.6, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '163', CDATE: '2026-08-14', CO_EP: 94.9, CO_LP: 87.5, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 93.6, CT_LP: 82.9, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 81.1, CI_LP: 75.8, CI_A: null, MO_EP: 66.2, MO_LP: 61,   MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 51.7, MT_LP: 44.3, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '164', CDATE: '2026-08-21', CO_EP: 95.8, CO_LP: 88.6, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 94.4, CT_LP: 84,   CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 87.7, CI_LP: 82.8, CI_A: null, MO_EP: 68.9, MO_LP: 62.5, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 55.5, MT_LP: 46.4, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '165', CDATE: '2026-08-28', CO_EP: 96.5, CO_LP: 89.7, CO_A: 100,  CH_EP: 100,  CH_LP: 100,  CH_A: 100,  CT_EP: 95.2, CT_LP: 85.3, CT_A: 100,  CL_EP: 100,  CL_LP: 100,  CL_A: 100,  CR_EP: 100,  CR_LP: 100,  CR_A: 100,  CI_EP: 92.1, CI_LP: 88.7, CI_A: 100,  MO_EP: 71.9, MO_LP: 64.3, MO_A: 100,  MH_EP: 100,  MH_LP: 100,  MH_A: 100,  MT_EP: 59.9, MT_LP: 49,   MT_A: 100,  ML_EP: 100,  ML_LP: 100,  ML_A: 100, },
{ NO: '166', CDATE: '2026-09-04', CO_EP: 97,   CO_LP: 90.7, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 95.9, CT_LP: 86.6, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 95.4, CI_LP: 93,   CI_A: null, MO_EP: 75.1, MO_LP: 66.5, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 64.4, MT_LP: 52.1, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '167', CDATE: '2026-09-11', CO_EP: 97.5, CO_LP: 91.8, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 96.4, CT_LP: 88,   CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 97.6, CI_LP: 95.9, CI_A: null, MO_EP: 78.4, MO_LP: 68.7, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 69.2, MT_LP: 55.3, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '168', CDATE: '2026-09-18', CO_EP: 97.8, CO_LP: 92.4, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 96.7, CT_LP: 88.9, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 98.6, CI_LP: 97.2, CI_A: null, MO_EP: 80.4, MO_LP: 70.3, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 71.9, MT_LP: 57.6, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '169', CDATE: '2026-09-25', CO_EP: 98.1, CO_LP: 93.5, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 97.2, CT_LP: 90.3, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 99.2, CI_LP: 98.6, CI_A: null, MO_EP: 83.5, MO_LP: 72.9, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 76.4, MT_LP: 61.3, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '170', CDATE: '2026-10-02', CO_EP: 98.4, CO_LP: 94.2, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 97.6, CT_LP: 91.5, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 99.6, CI_LP: 99.2, CI_A: null, MO_EP: 85.9, MO_LP: 75,   MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 79.9, MT_LP: 64.3, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '171', CDATE: '2026-10-09', CO_EP: 98.6, CO_LP: 95.1, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 97.9, CT_LP: 92.7, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 99.9, CI_LP: 99.7, CI_A: null, MO_EP: 88.6, MO_LP: 77.8, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 83.7, MT_LP: 68.3, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '172', CDATE: '2026-10-16', CO_EP: 98.8, CO_LP: 95.9, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 98.2, CT_LP: 93.9, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 99.9, CI_A: null, MO_EP: 90.9, MO_LP: 80.4, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 87,   MT_LP: 72,   MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '173', CDATE: '2026-10-23', CO_EP: 99,   CO_LP: 96.6, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 98.5, CT_LP: 94.9, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 92.7, MO_LP: 83.1, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 89.6, MT_LP: 75.9, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '174', CDATE: '2026-10-30', CO_EP: 99.2, CO_LP: 97.2, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 98.8, CT_LP: 95.8, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 94.1, MO_LP: 85.6, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 91.6, MT_LP: 79.4, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '175', CDATE: '2026-11-06', CO_EP: 99.3, CO_LP: 97.7, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99,   CT_LP: 96.6, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 95.4, MO_LP: 87.9, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 93.4, MT_LP: 82.7, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '176', CDATE: '2026-11-13', CO_EP: 99.4, CO_LP: 98.2, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.1, CT_LP: 97.2, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 96.6, MO_LP: 89.8, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 95.1, MT_LP: 85.5, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '177', CDATE: '2026-11-20', CO_EP: 99.5, CO_LP: 98.5, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.2, CT_LP: 97.8, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 97.9, MO_LP: 91.6, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 97,   MT_LP: 88,   MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '178', CDATE: '2026-11-27', CO_EP: 99.6, CO_LP: 98.8, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.4, CT_LP: 98.3, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 98.8, MO_LP: 93.1, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 98.3, MT_LP: 90.1, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '179', CDATE: '2026-12-04', CO_EP: 99.7, CO_LP: 99.2, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.6, CT_LP: 98.8, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.3, MO_LP: 94.4, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99,   MT_LP: 92,   MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '180', CDATE: '2026-12-11', CO_EP: 99.8, CO_LP: 99.4, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.7, CT_LP: 99.1, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.5, MO_LP: 95.4, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.3, MT_LP: 93.5, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '181', CDATE: '2026-12-18', CO_EP: 99.8, CO_LP: 99.5, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.7, CT_LP: 99.2, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.6, MO_LP: 96.1, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.4, MT_LP: 94.4, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '182', CDATE: '2026-12-25', CO_EP: 99.8, CO_LP: 99.6, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.7, CT_LP: 99.4, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.6, MO_LP: 96.7, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.4, MT_LP: 95.2, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '183', CDATE: '2027-01-01', CO_EP: 99.8, CO_LP: 99.6, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.7, CT_LP: 99.5, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.6, MO_LP: 97.2, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.4, MT_LP: 96.1, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '184', CDATE: '2027-01-08', CO_EP: 99.8, CO_LP: 99.7, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.7, CT_LP: 99.6, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.6, MO_LP: 97.9, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.4, MT_LP: 97,   MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '185', CDATE: '2027-01-15', CO_EP: 99.8, CO_LP: 99.7, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.7, CT_LP: 99.6, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.6, MO_LP: 98.3, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.4, MT_LP: 97.6, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '186', CDATE: '2027-01-22', CO_EP: 99.8, CO_LP: 99.8, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.7, CT_LP: 99.7, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.6, MO_LP: 98.6, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.4, MT_LP: 98,   MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '187', CDATE: '2027-01-29', CO_EP: 99.9, CO_LP: 99.8, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.8, CT_LP: 99.8, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.6, MO_LP: 98.8, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.4, MT_LP: 98.3, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '188', CDATE: '2027-02-05', CO_EP: 99.9, CO_LP: 99.9, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.9, CT_LP: 99.8, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.6, MO_LP: 99.1, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.4, MT_LP: 98.7, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '189', CDATE: '2027-02-12', CO_EP: 99.9, CO_LP: 99.9, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.9, CT_LP: 99.9, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 99.9, MO_LP: 99.6, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 99.8, MT_LP: 99.4, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '190', CDATE: '2027-02-19', CO_EP: 99.9, CO_LP: 99.9, CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 99.9, CT_LP: 99.9, CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 100,  MO_LP: 99.9, MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 100,  MT_LP: 99.9, MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '191', CDATE: '2027-02-26', CO_EP: 100,  CO_LP: 100,  CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 100,  CT_LP: 100,  CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 100,  MO_LP: 100,  MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 100,  MT_LP: 100,  MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '192', CDATE: '2027-03-05', CO_EP: 100,  CO_LP: 100,  CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 100,  CT_LP: 100,  CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 100,  MO_LP: 100,  MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 100,  MT_LP: 100,  MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '193', CDATE: '2027-03-12', CO_EP: 100,  CO_LP: 100,  CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 100,  CT_LP: 100,  CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 100,  MO_LP: 100,  MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 100,  MT_LP: 100,  MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '194', CDATE: '2027-03-19', CO_EP: 100,  CO_LP: 100,  CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 100,  CT_LP: 100,  CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 100,  MO_LP: 100,  MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 100,  MT_LP: 100,  MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '195', CDATE: '2027-03-26', CO_EP: 100,  CO_LP: 100,  CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 100,  CT_LP: 100,  CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 100,  MO_LP: 100,  MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 100,  MT_LP: 100,  MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },
{ NO: '196', CDATE: '2027-04-02', CO_EP: 100,  CO_LP: 100,  CO_A: null, CH_EP: 100,  CH_LP: 100,  CH_A: null, CT_EP: 100,  CT_LP: 100,  CT_A: null, CL_EP: 100,  CL_LP: 100,  CL_A: null, CR_EP: 100,  CR_LP: 100,  CR_A: null, CI_EP: 100,  CI_LP: 100,  CI_A: null, MO_EP: 100,  MO_LP: 100,  MO_A: null, MH_EP: 100,  MH_LP: 100,  MH_A: null, MT_EP: 100,  MT_LP: 100,  MT_A: null, ML_EP: 100,  ML_LP: 100,  ML_A: null, },

  ],

  Queries: {

    SQL1: [ // Timeline
      { SN: '1',  CDATE: '2025-01-31', },
      { SN: '2',  CDATE: '2025-02-28', },
      { SN: '3',  CDATE: '2025-03-28', },
      { SN: '4',  CDATE: '2025-04-25', },
      { SN: '5',  CDATE: '2025-05-30', },
      { SN: '6',  CDATE: '2025-06-27', },
      { SN: '7',  CDATE: '2025-07-25', },
      { SN: '8',  CDATE: '2025-08-29', },
      { SN: '9',  CDATE: '2025-09-26', },
      { SN: '10', CDATE: '2025-10-31', },
      { SN: '11', CDATE: '2025-11-28', },
      { SN: '12', CDATE: '2025-12-26', },
      { SN: '13', CDATE: '2026-01-30', },
      { SN: '14', CDATE: '2026-02-27', },
      { SN: '15', CDATE: '2026-03-27', },
      { SN: '16', CDATE: '2026-04-24', },
      { SN: '17', CDATE: '2026-05-29', },
      { SN: '18', CDATE: '2026-06-26', },
      { SN: '19', CDATE: '2026-07-31', },
      { SN: '20', CDATE: '2026-08-28', },
      { SN: '21', CDATE: '2026-09-25', },
      { SN: '22', CDATE: '2026-10-30', },
      { SN: '23', CDATE: '2026-11-27', },
      { SN: '24', CDATE: '2026-12-25', },
      { SN: '25', CDATE: '2027-01-29', },
      { SN: '26', CDATE: '2027-02-26', },
      { SN: '27', CDATE: '2027-03-26', },

    ],

    SQL2: [ // Timeline
      {
        CUTOFF:                 '2025-05-30',
      }
    ],

  },
  
}