import __C from '../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 550,
    CanvasHeight: 300,
    CanvasBorderWeight: .0,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#FFFFFF',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 220,
    CanvasChartHeight: 300,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {PE_WK: 4.1,   PL_WK:7.1,     ACT_WK:5.2,   PE_CUM:52.1,     PL_CUM:58.9,     ACT_CUM: 55.5},
  ],

  Queries: {
    SQL1: [
      {
        X:                    10,
        Y:                    10,
        TUBE_COLOR:           'LightBlue',
        TUBE_HEIGHT:          150,
        TUBE_TICKNESS:        60,
        TUBE_TEXT_COLOR:     '#333333',
        TUBE_TEXT_SIZE:      11,

        BASE_TEXT_COLOR:     '#44A9DF',
        BASE_TEXT_SIZE:      15,
        BASE_HEIGHT:          35,
        BASE_PADDING:         27,

        MAIN_COLOR:           '#000000',
        MAIN_SIZE:            11,

        SUB_HEADER_COLOR:     '#757575',
        SUB_HEADER_SIZE:      9,
        SUB_VALUE_COLOR:      '#000000',
        SUB_VALUE_SIZE:       11,
        
        TEXT_SIZE_DIFF:       2,
        TEXT_PADDING:         60,
        TEXT_CUM_PADDING:     50,

        REF_CODE:             ' PE_WK     / PL_WK     / ACT_WK    / PE_CUM /  PL_CUM / ACT_CUM ',


      },
    ],

    // Filter
    SQL2: [
      {
        F1: '',
        F2: '',
        F3: '',
      },      
    ]
  },
}